import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useParams, useNavigate } from 'react-router-dom';
import pageStyles from '../styles/page-common.module.css';
import formStyles from '../styles/form-common.module.css';
import { resetPassword } from '../backend';


function PasswordReset() {
  const [loading, setLoading] = useState(false);
  const { token } = useParams();
  const [resetSuccess, setResetSuccess] = useState(0)
  const [message, setMessage] = useState('');
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    confirmPassword: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.user).isLoggedIn;

  // shouldn't be here if logged in; navigate to home
  useEffect(() => {
    if (isLoggedIn) {
      navigate('/home');
    }
  }, [navigate, isLoggedIn]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      setErrorMessage('Passwords do not match');
      return;
    }

    setLoading(true);

    // Send registration data to the server (backend)
    try {
      await resetPassword(formData.username, formData.password, token);
      setLoading(false);
      setMessage('Password reset successful! Please sign in below.');
      setResetSuccess(1)
    } catch (error) {
      setLoading(false);
      setMessage('Password reset failed. Perhaps the giver username was wrong or the link was invalid/expired. Please try again or resend reset email below.');
      setResetSuccess(-1)
    }
  };

  useEffect(() => {
    const confirm = async () => {

    };

    confirm();
  }, [token]);

  return (
    <div className={pageStyles.styledPage}>
      <h2>Password Reset</h2>
      {resetSuccess===0 &&
        <form onSubmit={handleSubmit} className={formStyles.styledForm}>
          <label>
            Username:
            <input
              type="text"
              name="username"
              placeholder="Username" 
              value={formData.username}
              onChange={handleInputChange}
              required
            />
          </label>
          <label>
            New password:
            <input
              type="password"
              name="password"
              placeholder="Password" 
              value={formData.password}
              onChange={handleInputChange}
              required
            />
          </label>
          <label>
            Confirm new password:
            <input
              type="password"
              name="confirmPassword"
              placeholder="Password" 
              value={formData.confirmPassword}
              onChange={handleInputChange}
              required
            />
          </label>
          <button type="submit" disabled={loading}>
            {loading ? 'Resetting...' : 'Reset Password'}
          </button>
        </form>
      }
      {resetSuccess===0 && errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
      <p>{message}</p>
      {resetSuccess===1 && <NavLink to='/login'>Sign in</NavLink>}
      {resetSuccess===-1 && <NavLink to='/forgotten-password'>Resend reset email</NavLink>}
    </div>
  );
}

export default PasswordReset;
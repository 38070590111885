import { fetchToken, getUserData } from "./backend";
import store from "./store";
import { loginUser, logoutUser } from "./slices/userSlice";

async function login(username) {
  try {
    const userData = await getUserData(username);
    localStorage.setItem('username', username);
    store.dispatch(loginUser(userData));    
  } 
  catch(error) {
    console.log(error);
    throw new Error("Login unsuccessful.")
  }
}

async function authenticate(username, password) {
  try {
    await fetchToken(username, password);
    await login(username)
  }
  catch (error) {
    console.log(error);
    throw new Error("Login unsuccessful.")
  }
  return;
}

async function logout() {
  store.dispatch(logoutUser());
  localStorage.removeItem('username');
  if(process.env.NODE_ENV==='development') {
    document.cookie = "csrf_access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; SameSite=Strict;";
  } else {
    document.cookie = "csrf_access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.polygloss.io; Secure; SameSite=Strict;";
  }
}



export { login, authenticate, logout }
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LanguageDropdown from '../components/LanguageDropdown';
import pageStyles from '../styles/page-common.module.css';
import { LONG_NAMES } from '../constants';


function Home() {
  const user = useSelector((state) => state.user);

  return (
    <div className={pageStyles.styledPage}>
      <h2>Welcome, {user.username}!</h2>
      <p>Choose your learning language:</p>
      <LanguageDropdown/>
      <p>Current {LONG_NAMES[user.currentLanguage]} level: {user.levels[user.currentLanguage]}</p>
      <Link to="/review" style={{display: 'contents'}}><button>Start</button></Link>
    </div>
  );
}

export default Home;
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import pageStyles from '../styles/page-common.module.css';
import formStyles from '../styles/form-common.module.css';
import { sendForgottenUsernameEmail } from '../backend';


function ForgottenUsername() {
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.user).isLoggedIn;

  // shouldn't be here if logged in; navigate to home
  useEffect(() => {
    if (isLoggedIn) {
      navigate('/home');
    }
  }, [navigate, isLoggedIn]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleConfirmEmailChange = (e) => {
    setConfirmEmail(e.target.value);
  };

  const handleResendClick = async (e) => {
    e.preventDefault();

    if (!email || !confirmEmail) {
      setMessage('Please enter your email address in both fields.');
      return;
    }

    if (email !== confirmEmail) {
      setMessage('Email addresses do not match.');
      return;
    }

    setLoading(true);
    setMessage('');

    try {
      await sendForgottenUsernameEmail(email)
    } catch(error) {
      // do nothing, don't reveal that user doesn't exist
    }
    setMessage('If user exists, email has been sent.');
    setLoading(false);
    setEmail('');
    setConfirmEmail('');
  };

  return (
    <div className={pageStyles.styledPage}>
      <h2>Forgotten Username</h2>
      <p>Enter your email address below and we will send you a reminder email with your username.</p>
      <form onSubmit={handleResendClick} className={formStyles.styledForm}>
        <label>
          Email:
          <input
            type="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="Enter your email address"
          />
        </label>
        <label>
          Confirm email:
          <input
            type="email"
            value={confirmEmail}
            onChange={handleConfirmEmailChange}
            placeholder="Confirm your email address"
          />
        </label>
        
        <button type="submit" disabled={loading}>
          {loading ? 'Sending...' : 'Send'}
        </button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
}

export default ForgottenUsername;
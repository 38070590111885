import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserData } from '../backend';
import { setLanguage } from '../slices/userSlice';
import languageDropdownStyles from '../styles/component-language-dropdown.module.css';

//
// Dropdown menu to choose language for review. Initial state is user's
// currentLanguage property. Changing updates user's currentLanguage in
// redux state AND on backend.
//
function LanguageDropdown() {
  
  // get user redux state
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const options = useMemo(() => {
    return [
      {value: 'fr', label: 'French'},
      {value: 'de', label: 'German'},
      {value: 'es', label: 'Spanish'}
    ]
  }, []);

  // react state for dropdown option
  const [selectedOption, setSelectedOption] = useState(options.find(option => option.value === user.currentLanguage));
  
  // this effect is here to make sure pre-selected dropdown option is updated
  // when user is finished logging in
  useEffect(() => {
    setSelectedOption(options.find(option => option.value === user.currentLanguage));
  }, [user, options])

  function handleChange(event) {
    const selectedValue = event.target.value;
    const selected = options.find(option => option.value === selectedValue);
    setSelectedOption(selected);
    dispatch(setLanguage(selected.value));
    updateUserData(user.username, { currentLanguage: selected.value });
  }

  return (
    <select value={selectedOption?.value} onChange={handleChange} className={languageDropdownStyles.languageDropdown}>
      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );

}

export default LanguageDropdown;
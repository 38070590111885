import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    isLoggedIn: false,
    currentLanguage: "",
    email: "",
    hash: "",
    lastReviewDate: null,
    levels: null,
    streak: 0,
    username: "",
  },
  reducers: {
    loginUser: (state, action) => {
      state.currentLanguage = action.payload.currentLanguage;
      state.email = action.payload.email;
      state.hash = action.payload.hash;
      state.lastReviewDate = action.payload.lastReviewDate;
      state.levels = action.payload.levels;
      state.streak = action.payload.streak;
      state.username = action.payload.username;
      state.isLoggedIn = true;
    },
    logoutUser: (state) => {
      state.isLoggedIn = false;
      state.currentLanguage = "";
      state.email = "";
      state.hash = "";
      state.lastReviewDate = null;
      state.levels = null;
      state.streak = 0;
      state.username = "";
    },
    setLanguage: (state, action) => { 
      state.currentLanguage = action.payload;
    },
    setLevel: (state, action) => {
      const {language, level} = action.payload;
      state.levels = {...state.levels, [language]: level}
    },
    setLastReviewDate: (state, action) => {
      state.lastReviewDate = action.payload;
    },
    setStreak: (state, action) => {
      state.streak = action.payload;
    } 
  },
});

export const { loginUser, logoutUser, setLanguage, setLevel, setLastReviewDate, setStreak } = userSlice.actions;
export default userSlice.reducer;

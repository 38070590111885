import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import pageStyles from '../styles/page-common.module.css';
import formStyles from '../styles/form-common.module.css';
import { sendForgottenPasswordEmail } from '../backend';


function ForgottenPassword() {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.user).isLoggedIn;

  // shouldn't be here if logged in; navigate to home
  useEffect(() => {
    if (isLoggedIn) {
      navigate('/home');
    }
  }, [navigate, isLoggedIn]);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSendClick = async (e) => {
    e.preventDefault();

    if (!username) {
      setMessage('Please enter your username.');
      return;
    }

    if (!email) {
      setMessage('Please enter your email address.');
      return;
    }

    setLoading(true);
    setMessage('');

    try {
      await sendForgottenPasswordEmail(email, username)
    } catch(error) {
      // do nothing, don't reveal that user doesn't exist
    }
    setMessage('If user exists, email has been sent.');
    setLoading(false);
    setUsername('');
    setEmail('');
  };

  return (
    <div className={pageStyles.styledPage}>
      <h2>Forgotten Password</h2>
      <p>Enter your username and email address below and we will send you an email with a password reset link.</p>
      <form onSubmit={handleSendClick} className={formStyles.styledForm}>
        <label>
          Username:
          <input
            type="text"
            value={username}
            onChange={handleUsernameChange}
            placeholder="Enter your username"
          />
        </label>
        <label>
          Email:
          <input
            type="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="Enter your email address"
          />
        </label>        
        <button type="submit" disabled={loading}>
          {loading ? 'Sending...' : 'Send'}
        </button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
}

export default ForgottenPassword;
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, NavLink } from 'react-router-dom';
import pageStyles from '../styles/page-common.module.css';
import { setLevel, setStreak, setLastReviewDate } from '../slices/userSlice';
import { updateUserData } from '../backend';
import { isLastReviewBeforeToday } from '../utils';


function ReviewComplete() {

  const dispatch = useDispatch();

  // get review results from page router 'location'
  const location = useLocation();

  const user = useSelector((state) => state.user);

  const [levelUp, setLevelUp] = useState(false);
  const [levelDown, setLevelDown] = useState(false);
  const [cantLevelUp, setCantLevelUp] = useState(false);
  const [cantLevelDown, setCantLevelDown] = useState(false);

  useEffect(() => {
  
    const reviewResults = location.state || {};
    const oldLevel = reviewResults['level'];
    const levelUpIndex = reviewResults['easy'] - reviewResults['hard'];
    const language = user.currentLanguage

    if(isLastReviewBeforeToday(user.lastReviewDate)) {

      const today = new Date();
      const lastReviewDate = {
        'year': today.getFullYear(),
        'month': today.getMonth(),
        'date': today.getDate(),
      }

      const newStreak = user.streak + 1;
      dispatch(setStreak(newStreak));
      updateUserData(user.username, {streak: newStreak});

      dispatch(setLastReviewDate(lastReviewDate));
      updateUserData(user.username, {lastReviewDate: lastReviewDate});
    }

    if(levelUpIndex <= -3) {
      if(oldLevel === 1) {
        setCantLevelDown(true);
      }
      else if((oldLevel >= 2 && oldLevel <= 35)) {
        setLevelDown(true);
        const newLevel = oldLevel - 1;
        if(user.levels[user.currentLanguage] === oldLevel) {
          dispatch(setLevel({'language': language, 'level': newLevel}))
          updateUserData(user.username, {levels: {...user.levels, [language]: newLevel}});
        }
      }
    } else if(levelUpIndex >= 3) {
      if(oldLevel === 35) {
        setCantLevelUp(true);
      }
      else if(oldLevel >= 1 && oldLevel <= 34) {
        setLevelUp(true);
        const newLevel = oldLevel + 1;
        if(user.levels[user.currentLanguage] === oldLevel) {
          dispatch(setLevel({'language': language, 'level': newLevel}))
          updateUserData(user.username, {levels: {...user.levels, [language]: newLevel}});
        }
      }
    }
  }, [user, location, dispatch]);

  return (
    <div className={pageStyles.styledPage}>
      <h2>Review complete!</h2>
      <div>
        <h3>Results: </h3>
        <p>Too easy: {location.state['easy']}</p>
        <p>Just right: {location.state['justRight']}</p>
        <p>Too hard: {location.state['hard']}</p>
      </div>
      {levelUp && <h3>Levelling up to Level {location.state['level'] + 1}!</h3>}
      {levelDown && <h3>Levelling down to Level {location.state['level'] - 1}!</h3>}
      {cantLevelUp && <h3>Can't level up any further!</h3>}
      {cantLevelDown && <h3>Can't reduce level further, just keep at it!</h3>}
      <NavLink to="/review" style={{display: 'contents'}}><button>Another session</button></NavLink>
      <NavLink to="/" style={{display: 'contents'}}><button>Return to homepage</button></NavLink>
    </div>
  );
}

export default ReviewComplete;
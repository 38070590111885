import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import './styles/index.css';
import App from './App';
import store from './store';

const root = ReactDOM.createRoot(document.getElementById('root'));

function render() {
  root.render(
    <Provider store={store}>
      <Router>
        <App/>
      </Router>
    </Provider>
  );  
}
render();
const unsubscribe = store.subscribe(render);

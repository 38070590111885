import { useEffect } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import pageStyles from '../styles/page-common.module.css';
import { LONG_NAMES } from '../constants';

function Landing() {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.user).isLoggedIn;

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/home');
    }
  }, [navigate, isLoggedIn]);

  return (
    <div className={pageStyles.styledPage}>
      <h2>Welcome to Polygloss!</h2>
      <h3>What is Polygloss?</h3>
      <p>Polygloss is a web app which will help sharpen your foreign language reading skills.</p>
      <br/>
      <h3>How does it work?</h3>
      <p>In each session, you get shown a series of passages in your target language.</p>
      <p>Try and read them, then tell the app how easy you found it. The app will adjust to your level.</p>
      <br/>
      <h3>Which languages can I learn?</h3>
      <ul>
        {Object.values(LONG_NAMES).map((value, index)=><li key={index}>{value}</li>)}
      </ul>
      <p>Currently, the app is aimed at learners who already speak English. This will change in future.</p>
      <br/>
      <h3>Where do I begin?</h3>
      <p>Please use the links below to login or create a new account.</p>
      <br/>
      <NavLink to="/login" style={{display: 'contents'}}><button>Login</button></NavLink>
      <NavLink to="/register" style={{display: 'contents'}}><button>Register</button></NavLink>
      <p><NavLink to='/resend-confirmation'>Resend email confirmation</NavLink></p>
    </div>
  );
}

export default Landing;
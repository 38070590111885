import pageStyles from '../styles/page-common.module.css';

function Terms() {
  return (
    <div className={pageStyles.styledPage}>
      <h2>Terms of Service</h2>
      <p>Coming soon!</p>
    </div>
  );
}

export default Terms;
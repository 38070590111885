import { useEffect } from 'react';
import { Route, Routes,  Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setStreak } from './slices/userSlice';
import { updateUserData, checkToken } from './backend';
import Home from './pages/Home';
import About from './pages/About';
import FAQs from './pages/FAQs';
import Contact from './pages/Contact';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Review from './pages/Review';
import ReviewComplete from "./pages/ReviewComplete";
import Settings from './pages/Settings';
import Login from './pages/Login';
import Landing from './pages/Landing';
import NotFound from './pages/NotFound';
import Register from "./pages/Register";
import ResendConfirmation from './pages/ResendConfirmation';
import RegistrationSuccess from "./pages/RegistrationSuccess";
import ForgottenUsername from './pages/ForgottenUsername';
import ForgottenPassword from './pages/ForgottenPassword';
import PasswordReset from './pages/PasswordReset';
import ConfirmEmail from './pages/ConfirmEmail';
import Logout from "./pages/Logout";
import HeaderBar from "./components/HeaderBar";
import FooterBar from "./components/FooterBar";
import AccountDeletion from "./pages/AccountDeletion";
import AccountDeletionSuccess from './pages/AccountDeletionSuccess';
import { login } from './authentication';
import appStyles from './styles/app.module.css';


function PrivateRoutes({userInLocalStorage, isLoggedIn}) {
  return (
    userInLocalStorage ?
    <>
      {isLoggedIn && <Outlet/>}
    </>
    :
    <Navigate to='/logout'/>
  );
}


function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.user).isLoggedIn;
  const user = useSelector((state) => state.user);

  // when app loads up, if user in local storage, check token
  useEffect(() => {
    async function attemptRelogin() {
      const tokenValid = await checkToken(localStorage.getItem('username'))
      if(tokenValid) {
        login(localStorage.getItem('username'))
      } else {
        localStorage.removeItem('username')
        navigate('/logout')
      }
    }
    if(localStorage.getItem('username') && !isLoggedIn) {
      attemptRelogin();
    }
  }, [isLoggedIn]);

  // on every render, check if streak has expired, if so, set streak to zero
  useEffect(() => {
    if(isLoggedIn && user.streak !== 0) {
      const lastReviewDate = new Date(user.lastReviewDate.year, user.lastReviewDate.month, user.lastReviewDate.date)
      const today = new Date();
      const yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate());
      yesterday.setDate(yesterday.getDate() - 1)
      if(lastReviewDate < yesterday) {
        dispatch(setStreak(0));
        updateUserData(user.username, {streak: 0});
      }
    }
  });

  return (
    <div className={appStyles.appContainer}>
      <HeaderBar/>
      <main>
        <div className={appStyles.appMargin}>
        </div>
        <div className={appStyles.appCentre}>  
          <Routes>
            <Route element={<PrivateRoutes userInLocalStorage={localStorage.getItem('username')} isLoggedIn={isLoggedIn}/>}>
              <Route path="/home" element={<Home/>} />
              <Route path="/review" element={<Review/>} />
              <Route path="/review-complete" element={<ReviewComplete/>} />
              <Route path="/settings" element={<Settings/>} />
              <Route path="/account-deletion" element={<AccountDeletion/>} />
            </Route>
            <Route path="/" element={<Landing/>}/>
            <Route path="/about" element={<About/>}/>
            <Route path="/faqs" element={<FAQs/>}/>
            <Route path="/contact" element={<Contact/>}/>
            <Route path="/privacy" element={<Privacy/>}/>
            <Route path="/terms" element={<Terms/>}/>
            <Route path="/login" element={<Login/>} />
            <Route path="/logout" element={<Logout/>} />
            <Route path="/register" element={<Register/>} />
            <Route path="/resend-confirmation" element={<ResendConfirmation/>} />
            <Route path="/forgotten-username" element={<ForgottenUsername/>} />
            <Route path="/forgotten-password" element={<ForgottenPassword/>} />
            <Route path="/reset-password/:token" element={<PasswordReset/>} />
            <Route path="/registration-success" element={<RegistrationSuccess/>} />
            <Route path="/account-deletion-success" element={<AccountDeletionSuccess/>} />
            <Route path="/confirm/:token" element={<ConfirmEmail/>} />
            <Route path="*" element={<NotFound/>} />
          </Routes>
        </div>
        <div className={appStyles.appMargin}>
        </div>
      </main>

      <FooterBar/>
    </div>
  );
}

export default App;

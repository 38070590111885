
function isLastReviewBeforeToday(lastReviewDate) {
  if(lastReviewDate === null) {
    return true;
  }
  const today = new Date();
  if(today.getFullYear() > lastReviewDate.year) {
    return true;
  } else if(today.getMonth() > lastReviewDate.month) {
    return true;
  } else if(today.getDate() > lastReviewDate.date) {
    return true;
  }
  return false;
}

export { isLastReviewBeforeToday };
import pageStyles from '../styles/page-common.module.css';

function NextButtonContainer({handleEasy, handleJustRight, handleHard}) {
  return (
    <div style={{ 
      display: 'flex', 
      flexDirection: 'row',
      justifyContent: 'space-around',
      width: '80%' 
    }}>
      <button onClick={handleEasy} style={{minWidth:'30%'}}>TOO EASY</button>
      <button onClick={handleJustRight} style={{minWidth:'30%'}}>JUST RIGHT</button>
      <button onClick={handleHard} style={{minWidth:'30%'}}>TOO HARD</button>
    </div>
  );
}

export default NextButtonContainer;
import pageStyles from '../styles/page-common.module.css';


function NotFound() {

  return (
    <div className={pageStyles.styledPage}>
      <p>Page not found, sorry!</p>
    </div>
  );
}

export default NotFound;
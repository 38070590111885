import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import pageStyles from '../styles/page-common.module.css';
import settingsStyles from '../styles/page-settings.module.css';
import { LONG_NAMES } from '../constants';
import { setLevel } from '../slices/userSlice';
import { updateUserData } from '../backend';


function Settings() {

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [currentLevels, setCurrentLevels] = useState(user.levels);

  // // Handler function to update the state and trigger actions
  // const handleLevelChange = (language, newValue) => {
  //   dispatch(setLevel({'language': language, 'level': newValue}))
  //   updateUserData(user.username, {levels: {...user.levels, [language]: newValue}});
  // };
  const handleLevelChange = (language, value) => {
    setCurrentLevels({
      ...currentLevels,
      [language]: value,
    });
  }

  const handleReset = (language) => {
    setCurrentLevels({
      ...currentLevels,
      [language]: user.levels[language],
    });
  };

  const incrementValue = (language) => {
    const newValue = Math.min(currentLevels[language] + 1, 35);
    handleLevelChange(language, newValue);
  };

  const decrementValue = (language) => {
    const newValue = Math.max(currentLevels[language] - 1, 1);
    handleLevelChange(language, newValue);
  };

  const handleConfirm = (language) => {
    dispatch(setLevel({'language': language, 'level': currentLevels[language]}))
    updateUserData(user.username, {levels: {...user.levels, [language]: currentLevels[language]}});
  };

  const isDefault = (language) => currentLevels[language] === user.levels[language];

  return (
    <div className={pageStyles.styledPage}>
      <h2>Settings</h2>
      <h3>Levels</h3>
      <p>Use the arrows to increase/decrease your level (1: easiest, 35: hardest)</p>
      <div className={settingsStyles['levelSettingsContainer']}>
        {Object.keys(user.levels).map((language) => (
          <div key={language} className={settingsStyles['languageContainer']}>
            <label className={settingsStyles['languageLabel']}>
              {LONG_NAMES[language]}:
            </label>
            <div className={settingsStyles['inputContainer']}>
              <button 
                onClick={() => decrementValue(language)} 
                className={settingsStyles['stepButton']}
              >
                -
              </button>
              <div className={settingsStyles['languageLevelContainer']}><p>{currentLevels[language]}</p></div>
              <button 
                onClick={() => incrementValue(language)} 
                className={settingsStyles['stepButton']}
              >
              +
              </button>
            </div>
            <div className={settingsStyles['languageButtonContainer']}>
              <button onClick={() => handleReset(language)} className={settingsStyles['languageButton']} disabled={isDefault(language)}>Reset</button>
              <button onClick={() => handleConfirm(language)} className={settingsStyles['languageButton']} disabled={isDefault(language)}>Confirm</button>
            </div>
          </div>
        ))}
      </div>
      <br/>
      <h3>Account Management</h3>
      <Link to="/account-deletion" style={{display: 'contents'}}><button>Delete Account</button></Link>
    </div>
  );
}

export default Settings;
import mainTextboxStyles from '../styles/component-main-textbox.module.css';

function MainTextbox({textElement}) {
  return (
    <div className={mainTextboxStyles.mainTextbox}>
      {textElement}
    </div>
  );
}

export default MainTextbox;
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { logout } from "../authentication";
import pageStyles from '../styles/page-common.module.css';


function Logout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.user).isLoggedIn;

  useEffect(() => {
    if (isLoggedIn) {
      logout()
    }
    navigate('/');
  }, [navigate, dispatch, isLoggedIn]);

  return (
    <div className={pageStyles.styledPage}>
      <p>Logging out...</p>
    </div>
  );
}
  
export default Logout;
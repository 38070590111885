import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import pageStyles from '../styles/page-common.module.css';
import { logout } from "../authentication";

function AccountDeletionSuccess() {
  const isLoggedIn = useSelector((state) => state.user).isLoggedIn;

  useEffect(() => {
    if (isLoggedIn) {
      logout()
    }
  }, [isLoggedIn]);


  return (
    <div className={pageStyles.styledPage}>
      <h2>Account Deleted</h2>
      <NavLink to="/">Return to landing page</NavLink>
    </div>
  );
}

export default AccountDeletionSuccess;
import { NavLink } from 'react-router-dom';
import pageStyles from '../styles/page-common.module.css';

function Privacy() {
  return (
    <div className={pageStyles.styledPageLeftAligned}>
      <h2>Privacy Policy</h2>  
        <strong>Effective Date: October 1, 2024</strong>
        <p>At Polygloss, we are committed to protecting and respecting your privacy. This Privacy Policy outlines how we collect, use, store, and protect your personal data in accordance with UK and EU General Data Protection Regulation (GDPR) and other relevant privacy laws. Please read this policy carefully to understand how we handle your information.</p>
        
        <h3>1. Information We Collect</h3>
          <p>We only keep information on users who have registered for an account on Polygloss. We do not collect any sensitive personal information, and we do not track or monitor user behavior for advertising or analytics purposes. </p>
          <p>We keep the following information on our secure database (hosted by MongoDB):</p>
          <ul>
            <li>Email address</li>
            <li>Password (stored in a secure hashed format; see below)</li>
            <li>Additionally data essential to functionality of the app, such as your language level, daily streak, and progress within the app.</li>
          </ul>
          <p>Our subscription payments are managed by Stripe. Sensitive payment information such as credit card numbers are invisible to us, and we only see:</p>
          <ul>
            <li>Billing name</li>
            <li>Billing address</li>
            <li>Billing payment type</li>
          </ul>
          <p>See Third-Party Services below for more information about both MongoDB and Stripe, and for links to their privacy policies.</p>
  
        <h3>2. How We Use Your Data</h3>
          <p>The data we collect is used for the following purposes:</p>
          <ul>
            <li><strong>Account Management:</strong> To enable you to create an account, log in, and use Polygloss.</li>
            <li><strong>Subscription payments:</strong> We use Stripe, a third-party payment processor, to handle all payments and billing information.</li>
            <li><strong>Functionality:</strong> To track your language-learning progress and personalize the content provided based on your language level.</li>
            <li><strong>Communication:</strong> To send you essential information about your account, including password recovery or important updates.</li>
          </ul>
          <p>We do not share your personal information with third parties for marketing or any other purposes.</p>

        <h3>3. Cookies and Tracking</h3>
          <p>We use cookies only to manage authentication, keep you logged in, and ensure the security of your interactions within Polygloss. Specifically, we use:</p>
          <ul>
            <li><strong>JWT (JSON Web Tokens):</strong> For authentication and session management.</li>
            <li><strong>CSRF (Cross-Site Request Forgery) Access Tokens:</strong> For preventing cross-site request forgery in JWT-based authentication.</li>
          </ul>
          <p>These cookies are essential for the functioning of the Polygloss and are not used for tracking or analytics purposes.</p>
          <p>By registering for an account on Polygloss, you consent to our use of cookies as described above.</p>
      
        <h3>4. Third-Party Services</h3>
          <p>We use third-party services to provide certain functionalities within the App. These third-party providers are only given access to your personal data where necessary to perform their services and are obligated to protect your information.</p>
          <ul>
              <li><strong>Payment Processing:</strong> We use <strong>Stripe</strong> to process subscription payments. When you provide payment information, such as credit card details, this information is transmitted directly to Stripe. We do not store or have access to your payment information, but Stripe processes it securely in compliance with the Payment Card Industry Data Security Standards (PCI-DSS). For more information on how Stripe handles your data, you can review their Privacy Policy <a href="https://stripe.com/privacy" rel="noreferrer" target="_blank">here</a>.</li>           
              <li><strong>Data Storage:</strong> We use <strong>MongoDB</strong> to store your personal information, including email addresses, hashed passwords, and language progression data. Our MongoDB database is hosted in Ireland, within the European Economic Area (EEA), ensuring GDPR compliance. MongoDB provides secure storage services and is obligated to protect your data according to industry-standard practices. For more information, you can review MongoDB's Privacy Policy <a href="https://www.mongodb.com/legal/privacy-policy" rel="noreferrer" target="_blank">here</a>.</li>
          </ul>
          <p></p>

        <h3>5. Data Storage and Security</h3>
          <p>We store your data in a secure MongoDB database located in Ireland. This means that your personal information is processed and stored within the European Economic Area (EEA), ensuring compliance with UK and EU GDPR regulations. This does not include your payment data, which is managed by Stripe.</p>
          <p>The data stored includes your email address, password (in hashed format), and app-related data such as your language progression. We take industry-standard security measures to protect your personal information, including:</p>
          <ul>
              <li><strong>Password Hashing:</strong> Your password is stored in a hashed format to prevent unauthorized access.</li>
              <li><strong>Encryption:</strong> Sensitive data is encrypted during transmission using secure protocols.</li>
              <li><strong>Access Control:</strong> Access to personal data is restricted to authorized personnel only, and we regularly review our data security practices to ensure the highest level of protection.</li>
          </ul>
          <p>We do not transfer your personal data outside of the EEA. Should we need to transfer data in the future, we will take appropriate measures to ensure that your data is protected in accordance with applicable laws.</p>
          <p>Despite our best efforts, no method of data transmission or storage is completely secure, and we cannot guarantee the absolute security of your information.</p>
      
        <h3>6. Lawful Basis for Processing Personal Data</h3>
          <p>Under the GDPR, we rely on the following lawful bases for processing your personal data:</p>
          <ul>
              <li><strong>Contractual Necessity:</strong> We process your personal data, including your email address, language progression data, and hashed password, to provide the services outlined in our Terms of Service. This includes managing your account, delivering the app's functionality, and processing your subscription payments.</li>
              <li><strong>Legitimate Interests:</strong> We process certain data to improve the App's user experience and functionality, such as tracking your language progression and customizing content to your language level. These activities are necessary for the ongoing operation and improvement of the service, and we ensure that your rights and interests are protected.</li>
              <li><strong>Legal Obligations:</strong> We may process personal data to comply with legal requirements, such as financial reporting and tax obligations related to your subscription payments.</li>
          </ul>
          <p>Where applicable, we will obtain your explicit consent for any data processing that does not fall under the above lawful bases. You can withdraw your consent at any time by contacting us at [Your Contact Email].</p>

        <h3>7. Your Rights Under GDPR</h3>
          <p>As a user of the App, you have the following rights under the GDPR:</p>
          <ul>
              <li><strong>Access:</strong> You have the right to request access to the personal data we hold about you.</li>
              <li><strong>Rectification:</strong> You have the right to request that we correct any inaccurate or incomplete data.</li>
              <li><strong>Erasure:</strong> You have the right to request the deletion of your personal data (also known as the "right to be forgotten").</li>
              <li><strong>Restriction:</strong> You have the right to request that we limit the processing of your data in certain situations.</li>
              <li><strong>Portability:</strong> You have the right to request a copy of your data in a machine-readable format.</li>
              <li><strong>Objection:</strong> You have the right to object to the processing of your data in certain circumstances.</li>
          </ul>
          <p>To exercise any of these rights, please contact us (see Contact Us below).</p>
      
        <h3>8. Data Retention</h3>
          <p>We will retain your personal data for as long as your account is active or as necessary to comply with legal requirements. If you choose to delete your account or request erasure of your data, we will remove your data from our systems in accordance with applicable legal requirements.</p>
      
        <h3>9. Changes to This Privacy Policy</h3>
          <p>We may update this Privacy Policy from time to time. If we make significant changes, we will notify you by email or through the Polygloss interface. Your continued use of Polygloss after any changes indicates your acceptance of the updated Privacy Policy.</p>
      
        <h3>10. Contact Us</h3>
          <p>If you have any questions or concerns about this Privacy Policy or how your data is handled, please contact us via the <NavLink to='/contact'>Contact page</NavLink> of the site.</p>
    </div>
  );
}

export default Privacy;